import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer: React.FC = () => {
    return (
        <Box
            component="footer"
            sx={{
                width: '100%', // Ensure the footer spans the full width of the page
                bgcolor: 'background.paper',
                py: 3,
                mt: 'auto', // Pushes the footer to the bottom
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography variant="body2" color="text.primary">
                © {new Date().getFullYear()} American Polling Machine, LLC. All rights reserved.{' '}
                <Link href="/privacy-policy" color="inherit" sx={{ textDecoration: 'underline' }}>
                    Privacy Policy
                </Link>
            </Typography>
        </Box>
    );
};

export default Footer;
