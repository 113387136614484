import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { useParams } from 'react-router-dom';

const Verification: React.FC = () => {
    // Use useParams to get the status parameter from the URL
    const { status } = useParams<{ status: string }>();

    const isSuccess = status === 'success';

    return (
        <Container maxWidth="md">
            <Box sx={{ my: 5 }}>
                {isSuccess ? (
                    <>
                        <Typography
                            variant="h2"
                            gutterBottom
                            sx={{ fontFamily: 'Jancient, sans-serif', textAlign: 'center' }}
                        >
                            Email Verification is successful! Please login to the app and...
                        </Typography>
                        <Typography
                            variant="h2"
                            gutterBottom
                            sx={{ fontFamily: 'Jancient, sans-serif', textAlign: 'center' }}
                        >
                            LETS POLL!
                        </Typography>
                    </>
                ) : (
                    <Typography
                        variant="h2"
                        gutterBottom
                        sx={{ fontFamily: 'Jancient, sans-serif', textAlign: 'center', color: 'red' }}
                    >
                        Email Verification failed. Please try again or contact support.
                    </Typography>
                )}
            </Box>
        </Container>
    );
};

export default Verification;
