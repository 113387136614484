import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
    return (
        <Container maxWidth="md">
            <Box sx={{ my: 5 }}>
                <Typography variant="h2" gutterBottom sx={{ fontFamily: 'Jancient, sans-serif', textAlign: 'left' }}>
                    Privacy Policy
                </Typography>
                <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    1. Introduction
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    At American Polling Machine, LLC, we are committed to protecting the privacy and security of our users. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website, mobile applications, and services (collectively, the "Services").
                </Typography>
                <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    2. Information We Collect
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    We may collect personal information that you voluntarily provide to us when you register for our service. The types of personal information we may collect include your email address, phone number, and zip code.
                </Typography>
                <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    3. Use of Information
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    We use the information we collect to provide, maintain, and improve our Services, to communicate with you, and to personalize your experience. We may also use your information to send you updates, promotional materials, and other information that may be of interest to you.
                </Typography>
                <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    4. Information Sharing
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    We do not share, sell, rent, or disclose your personal information to any third parties. Your information is kept strictly confidential and is only used in accordance with this Privacy Policy.
                </Typography>
                <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    5. Data Security
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    We take the security of your personal information seriously. We implement appropriate technical and organizational measures to protect your data from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the Internet or method of electronic storage is completely secure.
                </Typography>
                <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    6. Your Rights
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    You have the right to access, correct, update, or delete your personal information at any time. If you wish to exercise any of these rights, please contact us at support@letspoll.com.
                </Typography>
                <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    7. Changes to This Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We will notify you of any significant changes by posting the new Privacy Policy on our website and updating the "Effective Date" at the top of this page. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
                </Typography>
                <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    8. Contact Us
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontFamily: 'Arial, sans-serif', textAlign: 'left' }}>
                    American Polling Machine, LLC
                    <br />
                    support@letspoll.com
                </Typography>
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;
