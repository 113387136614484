import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer'; 
import PrivacyPolicy from './pages/PrivacyPolicy'; 
import Verification from './pages/Verification'; 
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    Button,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './assets/APM_LOGO_FULL.png'; 
import appStoreBadge from './assets/app-store-badge.svg'; 
import playStoreBadge from './assets/play-store-badge.png'; 
import './App.css';
import ResetPassword from './pages/ResetPassword';

const theme = createTheme({
    palette: {
        background: {
            default: '#FFFFFF',
            paper: '#000000',
        },
        text: {
            primary: '#1e90ff',
        },
    },
    typography: {
        fontFamily: 'Jancient, sans-serif', // Default font
        h1: {
            fontSize: '4rem',
            fontWeight: 700,
            color: '#000000',
            '@media (max-width:600px)': {
                fontSize: '2rem',
            },
        },
        h2: {
            fontFamily: 'Arial, sans-serif', // Specific font override for h2
            fontSize: '3rem',
            fontWeight: 400,
            color: '#1e90ff',
            '@media (max-width:600px)': {
                fontSize: '2rem',
            },
        },
        button: {
            textTransform: 'none',
            fontSize: '1rem',
            color: '#000000',
        },
    },
});


const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Box
                    sx={{
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <AppBar
                        position="relative"
                        sx={{
                            bgcolor: 'background.paper',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                            paddingY: 4,
                        }}
                    >
                        <Toolbar
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={logo}
                                alt="APM Logo"
                                style={{
                                    height: '150px',
                                    width: 'auto',
                                    objectFit: 'contain',
                                }}
                            />
                        </Toolbar>
                    </AppBar>
                    <Routes>
                        <Route path="/" element={
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    bgcolor: 'background.default',
                                    padding: 10,
                                }}
                            >
                                <Typography variant="h1" align="center" gutterBottom sx={{ fontFamily: 'Jancient, sans-serif' }}>
                                    Where America Comes To Poll
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 2,
                                        marginTop: 1,
                                    }}
                                >
                                    <Button
                                        component="a"
                                        href="https://apps.apple.com/us/app/american-polling-machine/id6670273172"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ padding: 0 }}
                                    >
                                        <img
                                            src={appStoreBadge}
                                            alt="Download on the App Store"
                                            style={{
                                                height: '60px',
                                                width: 'auto',
                                            }}
                                        />
                                    </Button>
                                    <Button
                                        component="a"
                                        href="https://play.google.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ padding: 0 }}
                                    >
                                        <img
                                            src={playStoreBadge}
                                            alt="Get it on Google Play"
                                            style={{
                                                height: '60px',
                                                width: 'auto',
                                            }}
                                        />
                                    </Button>
                                </Box>
                            </Box>
                        } />
                        <Route path="privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/verification/:status" element={<Verification />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                    </Routes>

                    <Footer />
                </Box>
            </Router>
        </ThemeProvider>
    );
};

export default App;
